/* eslint-disable max-statements */
import React, {useEffect, useState} from "react";
import styles from "./styles.css";
import { CAR_LIST_EVENT_NAMES, NUMBER, PAGE_SOURCE } from "../../../constants/app-constants";
import InventoryCarCard from "../inventory-car-card";
import PropTypes from "prop-types";
import Filters from "../../../utils/filters-v2";
import { useHistory } from "react-router-dom";
import InventoryCarCardSkeleton from "../inventory-car-card/skeleton";

import { getCity } from "../../../utils/helpers/get-city-from-listing";
import { trackAmplitude, trackMobileCustomEventsAU} from "../../../tracking";
import { AMPLITUDE_EVENTS_CATEGORY} from "../../../tracking/amplitude-events";
import H2 from "../../shared/h2/component";
import OverflowContainer from "../../shared/container-overflow";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const FilterByPriceSlider = ({
    quotedPriceContent = [],
    config = {},
    fetchCarListConnect,
    updateSelectedSuggestionsConnect,
    updateFilterOptionDataConnect,
    clearAllFiltersConnect,
    reloadCarListConnect,
    updateAppliedFiltersConnect,
    updateAppliedSuggestionsConnect,
    allFilters,
    metaContent,
    tradeInToggleSelected,
    userTradeInData
}) => {

    const { tradeIn = false } =  userTradeInData || {};

    const {suggestions = [], key: filterKey} = allFilters.quotedPrice || {};
    const history = useHistory();

    const [selectedPriceFilter, setSelectedPriceFilter] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const fetchListing = async () => {
        const {code} = getCity(metaContent);
        const filters = new Filters({cityCode: code});
        const { payload } = filters.getListingPayload({
            selectedFilters: [{...selectedPriceFilter, isRangeFilter: true}],
            filters: allFilters
        });

        await fetchCarListConnect({
            size: 3,
            page: 0,
            ...(tradeIn && ({tradeIn: tradeInToggleSelected }))
        }, payload, "quotedPriceContent");
        setIsLoading(false);
    };

    const onClickCallback = async(event, item) => {
        await yieldToMain();
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.MOBILE_CARS_BY_PRICE_SELECTED, {"category": "car card", "value": item.appointmentId});
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CARS_BY_PRICE_CLICK, {
            eventLabel: item.appointmentId
        });
    };

    useEffect(() => {
        if (suggestions.length && !Object.keys(selectedPriceFilter).length) {
            const { key: optionKey, displayText, min, max} = suggestions[0];
            setSelectedPriceFilter({optionKey, displayText, min, max, filterKey});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [suggestions]);

    useEffect(() => {
        if (Object.keys(selectedPriceFilter).length) {
            fetchListing();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPriceFilter]);

    const handleOnTabClick = async(suggestion) => {
        if (selectedPriceFilter.optionKey === suggestion.key) {
            return;
        }
        setIsLoading(true);
        const { key: optionKey, displayText, min, max} = suggestion;
        setSelectedPriceFilter({optionKey, displayText, min, max, filterKey});
        await yieldToMain();
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.MOBILE_CARS_BY_PRICE_SELECTED, {"category": "section", "value": displayText});
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CARS_BY_PRICE_CLICK, {
            eventLabel: displayText
        });
    };

    const handleOnViewAllClick = async() => {
        clearAllFiltersConnect();
        await yieldToMain();
        const {optionKey, displayText, min, max} = selectedPriceFilter;
        const data = { filterKey, optionKey, rangeValues: {min, max}, isRangeFilter: true, isSelected: true, displayText };
        updateSelectedSuggestionsConnect({...data, displayText, isSingleEntity: true, isSuggestion: true});
        updateFilterOptionDataConnect(data);
        updateAppliedFiltersConnect();
        updateAppliedSuggestionsConnect();
        reloadCarListConnect(true);
        const {code} = getCity(metaContent);
        const filters = new Filters({cityCode: code});
        const { relativeURL } = filters.getListingPayload({ selectedFilters: [{...selectedPriceFilter, isRangeFilter: true}], filters: allFilters });
        trackAmplitude(AMPLITUDE_EVENTS_CATEGORY.MOBILE_CARS_BY_PRICE_SELECTED, {"category": "view all", "value": selectedPriceFilter.displayText});
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CARS_BY_PRICE_CLICK, {
            eventLabel: "view_all"
        });
        history.push(relativeURL);
    };

    return (
        <div className="container" styleName={"styles.section"}>
            <H2 text="Cars by price" />
            <div styleName={"styles.sectionWrapper"}>
                <OverflowContainer>
                    { suggestions.map((suggestion, index) => (
                        <p key={index} styleName={`styles.tabWrap ${ selectedPriceFilter.optionKey === suggestion.key ? "styles.activeTab" : ""}`} onClick={handleOnTabClick.bind(null, suggestion)}>{suggestion.displayText}</p>
                    ))
                    }
                </OverflowContainer>
            </div>
            <div>
                {isLoading ? Array(...new Array(NUMBER.THREE)).map((i, index) => {
                    return (
                        <InventoryCarCardSkeleton key={index} />
                    );
                }) :
                    quotedPriceContent.slice(0, NUMBER.THREE).map((item, index) => {
                        return (
                            <div styleName={"styles.carCardWrap"} key={index}>
                                <InventoryCarCard
                                    onClickCallback = {onClickCallback}
                                    config={config}
                                    impressionSource={AMPLITUDE_EVENTS_CATEGORY.CARS_BY_PRICE_SEEN}
                                    gtmFrom="home_page"
                                    // defaultTootlipStyle="styles.top"
                                    index={index}
                                    item={item}
                                    egcRenderType="listing"
                                    bodyType={item.body || item.bodyType}
                                    ampImpressionSource={CAR_LIST_EVENT_NAMES.PAGE_HOME}
                                    ampPageSource={PAGE_SOURCE.HOME_PAGE}
                                    {...({...item, emiDetails: item.emiDetails || {}}) }
                                />
                            </div>
                        );
                    })}
            </div>
            <p styleName={"styles.viewAllCars"} onClick={handleOnViewAllClick}>View more</p>
        </div>

    );
};

FilterByPriceSlider.propTypes = {
    quotedPriceContent: PropTypes.array,
    config: PropTypes.object,
    updateSelectedSuggestionsConnect: PropTypes.func,
    updateFilterOptionDataConnect: PropTypes.func,
    fetchCarListConnect: PropTypes.func,
    updateAppliedFiltersConnect: PropTypes.func,
    reloadCarListConnect: PropTypes.func,
    updateAppliedSuggestionsConnect: PropTypes.func,
    clearAllFiltersConnect: PropTypes.func,
    allFilters: PropTypes.object,
    metaContent: PropTypes.object,
    tradeInToggleSelected: PropTypes.bool,
    userTradeInData: PropTypes.object
};

export default FilterByPriceSlider;
